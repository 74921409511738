@font-face {
  font-family: WorkSans;
  font-weight: 200 900;
  font-style: normal;
  src: url("../fonts/Work_Sans/WorkSans-VariableFont_wght.ttf")
    format("truetype");
}
@font-face {
  font-family: WorkSans;
  font-weight: 200 900;
  font-style: italic;
  src: url("../fonts/Work_Sans/WorkSans-Italic-VariableFont_wght.ttf")
    format("truetype");
}

html,
body {
  font-family: "WorkSans";
  font-weight: 400;
}

.font-size-1 {
  font-size: 0.1rem;
}

.font-size-2 {
  font-size: 0.2rem;
}

.font-size-3 {
  font-size: 0.3rem;
}

.font-size-4 {
  font-size: 0.4rem;
}

.font-size-5 {
  font-size: 0.5rem;
}

.font-size-6 {
  font-size: 0.6rem;
}

.font-size-7 {
  font-size: 0.7rem;
}

.font-size-8 {
  font-size: 0.8rem;
}

.font-size-9 {
  font-size: 0.9rem;
}

.font-size-10 {
  font-size: 1rem;
}

.font-size-11 {
  font-size: 1.1rem;
}

.font-size-12 {
  font-size: 1.2rem;
}

.font-size-13 {
  font-size: 1.3rem;
}

.font-size-14 {
  font-size: 1.4rem;
}
.font-size-15 {
  font-size: 1.5rem;
}
.font-size-16 {
  font-size: 1.6rem;
}
.font-size-17 {
  font-size: 1.7rem;
}
.font-size-18 {
  font-size: 1.8rem;
}
.font-size-19 {
  font-size: 1.9rem;
}
.font-size-20 {
  font-size: 2rem;
}
.font-size-21 {
  font-size: 2.1rem;
}
.font-size-22 {
  font-size: 2.2rem;
}
.font-size-23 {
  font-size: 2.3rem;
}
.font-size-24 {
  font-size: 2.4rem;
}
.font-size-25 {
  font-size: 2.5rem;
}
.font-size-26 {
  font-size: 2.6rem;
}
.font-size-27 {
  font-size: 2.7rem;
}
.font-size-28 {
  font-size: 2.8rem;
}
.font-size-29 {
  font-size: 2.9rem;
}
.font-size-30 {
  font-size: 3rem;
}
.font-size-31 {
  font-size: 3.1rem;
}
.font-size-32 {
  font-size: 3.2rem;
}
.font-size-33 {
  font-size: 3.3rem;
}
.font-size-34 {
  font-size: 3.4rem;
}
.font-size-35 {
  font-size: 3.5rem;
}
.font-size-36 {
  font-size: 3.6rem;
}
.font-size-37 {
  font-size: 3.7rem;
}
.font-size-38 {
  font-size: 3.8rem;
}
.font-size-39 {
  font-size: 3.9rem;
}
.font-size-40 {
  font-size: 4rem;
}

/* STRONG */
.font-size-14 strong {
  letter-spacing: 0.5px;
  font-weight: 650;
}

/* BADGE */
.badge {
  line-height: 1.35;
  font-weight: 600;
  letter-spacing: 0.1px;
}
