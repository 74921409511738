.mainlayout-parent {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.mainlayout-general {
  display: flex;
  flex-grow: 1;
}

.mainlayout-main {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.mainlayout-header,
.mainlayout-header-Mobile {
  align-items: center;
  background-color: #ffffff;
  border-bottom: #aaaaae 1px solid;
  display: flex;
  justify-content: center;
  min-height: 80px;
  position: fixed;
  z-index: 100;
}

.mainlayout-header {
  width: calc(100% - 250px);
}

.mainlayout-header-Mobile {
  width: calc(100% - 70px);
}

.mainlayout-sidebar,
.mainlayout-sidebar-Mobile {
  align-items: center;
  background-color: #fff;
  display: flex;
  flex-direction: column;
}

.mainlayout-sidebar {
  border-right: #aaaaae 1px solid;
  min-width: 250px;
}

.mainlayout-sidebar-Mobile {
  min-width: 70px;
}

.mainlayout-logo {
  align-items: center;
  display: flex;
  height: 80px;
  justify-content: center;
  position: fixed;  
  z-index: 100;
}

.mainlayout-poweredby {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  position: fixed;
  bottom: 8px;
  z-index: 100;
}

.mainlayout-menu,
.mainlayout-menu-mobile {
  display: flex;
  position: fixed;
  top: 92px;
  z-index: 100;
}
.mainlayout-menu {
  width: 220px;
}
.mainlayout-menu-mobile {
  width: 38px;
}

.mainlayout-content > div {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  margin-top: 80px;
  min-height: 90vh;
  padding: 25px;
}

.mainlayout-footer {
  background-color: #f2f2f5;
  display: flex;
  justify-content: center;
  padding: 10px;
}
