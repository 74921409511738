/* CONTAINER */
.viewContainer {
    margin: 0 auto;
    max-width: 1220px;
}

/* IMAGES */
.img-center {
    border: 1px solid #aaaaae;
    display: block;
    margin: 0 auto;
}
.img-700 {
    max-width: 700px;
}
.modal-content {
    background-color: transparent !important;
    border: 0px !important;
}

/* ALL CARDS */
.card-body {
    padding: 1rem 2.5rem;
}

/* BACKGROUNDS */
.bg-get {
    background-color: #70a83b !important;
}
.bg-post {
    background-color: #437dec !important;
}
.bg-put {
    background-color: #edb441 !important;
}
.bg-delete {
    background-color: #cb383f !important;
}
.bg-head {
    background-color: #aaaaae !important;
}
.bg-patch {
    background-color: #2b2c36 !important;
}

/* CODE */
.code {
    background-color: #2b2c36;
    border-radius: 0.5rem;
    color: #eaeff7;
    font-family: monospace;
    font-size: 0.8rem;
    margin-top: 0.5rem;
    padding: 1rem;
}
.react-syntax-highlighter-line-number {
    display: none;
}
.code-container span {
    padding: 2px;
}

/* WEBHOOKS */
.webhook-0 {
    order: 3;
}

/* FONDO HOME */
.homeBackground {
    background-image: url(../images/documentation.png);
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: contain;
}
