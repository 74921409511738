.title-menu {
  color: #2b2c36;
  font-size: 1.15rem;
}
.text-menu-active {
  background-color: #ebf2ff !important;
}

.text-menu,
.icono-menu {
  color: #2b2c36;
  transition: all 0.4s linear;
}

.mainlayout-menu li > a {
  background-color: transparent;
  display: block;
  padding: 0.5rem;
  transition: all 0.4s linear;
}
.mainlayout-menu li > a:hover {
  background-color: #ebf2ff;
}

.mainlayout-menu li:hover > .text-menu,
.mainlayout-menu li:hover > a > .icono-menu,
.text-menu-active > .icono-menu {
  color: #437dec;
}

.mainlayout-menu li {
  background-color: transparent;
  transition: all 0.4s linear;
}

.mainlayout-menu .submenu {
  padding: 0.5rem 1rem;
}

.icono-menu {
  font-size: 1.2rem;
  min-width: 2rem;
  padding: 0.625rem 1.2rem 0.625rem 0rem;
  width: 10%;
}

.has-arrow {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.has-arrow label {
  width: 60%;
}
.has-arrow i {
  width: 10%;
}
