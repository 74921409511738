/*------------------------------------
- COLOR primary
------------------------------------*/
.alert-primary {
  background-color: #cfe2ff;
  border-color: #e9f0fc;
  color: #1043a4;
}

.alert-primary hr {
  border-top-color: #d2e0fa;
}

.alert-primary .alert-link {
  color: #0b3075;
}

.badge-primary {
  background-color: #437dec;
  color: #fff;
}

.badge-primary[href]:hover,
.badge-primary[href]:focus {
  background-color: #175de4;
  color: #fff;
}

.bg-primary {
  background-color: #437dec !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #175de4 !important;
}

.border-primary {
  border-color: #437dec !important;
}

.btn-primary {
  background-color: #437dec;
  border-color: #437dec;
  color: #fff;
}

.btn-primary:hover {
  background-color: #2266e8;
  border-color: #175de4;
  color: #fff;
}

.btn-primary:focus,
.btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(67, 125, 236, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #437dec;
  border-color: #437dec;
  color: #fff;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  background-color: #175de4;
  border-color: #1558d6;
  color: #fff;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(67, 125, 236, 0.5);
}

.btn-outline-primary {
  background-color: transparent;
  border-color: #437dec;
  color: #437dec;
}

.btn-outline-primary:hover {
  background-color: #437dec;
  border-color: #437dec;
  color: #fff;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(67, 125, 236, 0.5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  background-color: transparent;
  color: #437dec;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  background-color: #437dec;
  border-color: #437dec;
  color: #fff;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(67, 125, 236, 0.5);
}

.list-group-item-primary {
  background-color: #e9f0fc;
  color: #1043a4;
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  background-color: #d2e0fa;
  color: #1043a4;
}

.list-group-item-primary.list-group-item-action.active {
  background-color: #1043a4;
  border-color: #1043a4;
  color: #fff;
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #e9f0fc;
}

.table-hover .table-primary:hover {
  background-color: #d2e0fa;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #d2e0fa;
}

.text-primary {
  color: #437dec !important;
}

a.text-primary:hover,
a.text-primary:focus {
  color: #175de4 !important;
}

/*------------------------------------
- COLOR soft-soft-primary
------------------------------------*/
.alert-soft-primary {
  background-color: #18b16810;
  border-color: #17815a10;
  color: #5e86f1;
}

.alert-soft-primary hr {
  border-top-color: #16014810;
}

.alert-soft-primary .alert-link {
  color: #2e62ed;
}

.badge-soft-primary {
  background-color: #cfdbfb;
  color: #212529;
}

.badge-soft-primary[href]:hover,
.badge-soft-primary[href]:focus {
  background-color: #9fb7f7;
  color: #212529;
}

.bg-soft-primary {
  background-color: #cfdbfb !important;
}

a.bg-soft-primary:hover,
a.bg-soft-primary:focus,
button.bg-soft-primary:hover,
button.bg-soft-primary:focus {
  background-color: #9fb7f7 !important;
}

.border-soft-primary {
  border-color: #cfdbfb !important;
}

.btn-soft-primary {
  background-color: #cfdbfb;
  border-color: #cfdbfb;
  color: #212529;
}

.btn-soft-primary:hover {
  background-color: #aec2f8;
  border-color: #9fb7f7;
  color: #212529;
}

.btn-soft-primary:focus,
.btn-soft-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(207, 219, 251, 0.5);
}

.btn-soft-primary.disabled,
.btn-soft-primary:disabled {
  background-color: #cfdbfb;
  border-color: #cfdbfb;
  color: #212529;
}

.btn-soft-primary:not(:disabled):not(.disabled):active,
.btn-soft-primary:not(:disabled):not(.disabled).active,
.show > .btn-soft-primary.dropdown-toggle {
  background-color: #9fb7f7;
  border-color: #91adf5;
  color: #212529;
}

.btn-soft-primary:not(:disabled):not(.disabled):active:focus,
.btn-soft-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-soft-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(207, 219, 251, 0.5);
}

.btn-outline-soft-primary {
  background-color: transparent;
  border-color: #cfdbfb;
  color: #cfdbfb;
}

.btn-outline-soft-primary:hover {
  background-color: #cfdbfb;
  border-color: #cfdbfb;
  color: #212529;
}

.btn-outline-soft-primary:focus,
.btn-outline-soft-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(207, 219, 251, 0.5);
}

.btn-outline-soft-primary.disabled,
.btn-outline-soft-primary:disabled {
  background-color: transparent;
  color: #cfdbfb;
}

.btn-outline-soft-primary:not(:disabled):not(.disabled):active,
.btn-outline-soft-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-soft-primary.dropdown-toggle {
  background-color: #cfdbfb;
  border-color: #cfdbfb;
  color: #212529;
}

.btn-outline-soft-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-soft-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-soft-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(207, 219, 251, 0.5);
}

.list-group-item-soft-primary {
  background-color: #17815a10;
  color: #5e86f1;
}

.list-group-item-soft-primary.list-group-item-action:hover,
.list-group-item-soft-primary.list-group-item-action:focus {
  background-color: #16014810;
  color: #5e86f1;
}

.list-group-item-soft-primary.list-group-item-action.active {
  background-color: #5e86f1;
  border-color: #5e86f1;
  color: #212529;
}

.table-soft-primary,
.table-soft-primary > th,
.table-soft-primary > td {
  background-color: #17815a10;
}

.table-hover .table-soft-primary:hover {
  background-color: #16014810;
}

.table-hover .table-soft-primary:hover > td,
.table-hover .table-soft-primary:hover > th {
  background-color: #16014810;
}

.text-soft-primary {
  color: #cfdbfb !important;
}

a.text-soft-primary:hover,
a.text-soft-primary:focus {
  color: #9fb7f7 !important;
}

/*------------------------------------
- COLOR dark
------------------------------------*/
.alert-dark {
  background-color: #586aca;
  border-color: #495cc5;
  color: #000000;
}

.alert-dark hr {
  border-top-color: #3b4eb9;
}

.alert-dark .alert-link {
  color: #000000;
}

.badge-dark {
  background-color: #151c42;
  color: #fff;
}

.badge-dark[href]:hover,
.badge-dark[href]:focus {
  background-color: #080b1b;
  color: #fff;
}

.bg-dark {
  background-color: #151c42 !important;
}

a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #080b1b !important;
}

.border-dark {
  border-color: #151c42 !important;
}

.btn-dark {
  background-color: #151c42;
  border-color: #151c42;
  color: #fff;
}

.btn-dark:hover {
  background-color: #0c1026;
  border-color: #080b1b;
  color: #fff;
}

.btn-dark:focus,
.btn-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(21, 28, 66, 0.5);
}

.btn-dark.disabled,
.btn-dark:disabled {
  background-color: #151c42;
  border-color: #151c42;
  color: #fff;
}

.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  background-color: #080b1b;
  border-color: #04060f;
  color: #fff;
}

.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(21, 28, 66, 0.5);
}

.btn-outline-dark {
  background-color: transparent;
  border-color: #151c42;
  color: #151c42;
}

.btn-outline-dark:hover {
  background-color: #151c42;
  border-color: #151c42;
  color: #fff;
}

.btn-outline-dark:focus,
.btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(21, 28, 66, 0.5);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  background-color: transparent;
  color: #151c42;
}

.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  background-color: #151c42;
  border-color: #151c42;
  color: #fff;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(21, 28, 66, 0.5);
}

.list-group-item-dark {
  background-color: #495cc5;
  color: #000000;
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  background-color: #3b4eb9;
  color: #000000;
}

.list-group-item-dark.list-group-item-action.active {
  background-color: #000000;
  border-color: #000000;
  color: #fff;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #495cc5;
}

.table-hover .table-dark:hover {
  background-color: #3b4eb9;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #3b4eb9;
}

.text-dark {
  color: #151c42 !important;
}

a.text-dark:hover,
a.text-dark:focus {
  color: #080b1b !important;
}

.text-gray {
  color: #aaaaae;
}

/*------------------------------------
- COLOR success
------------------------------------*/
.alert-success {
  background-color: #d6eac4;
  border-color: #cce5b5;
  color: #334d1b;
}

.alert-success hr {
  border-top-color: #bfdea2;
}

.alert-success .alert-link {
  color: #1a270d;
}

.badge-success {
  background-color: #70a83b;
  color: #212529;
}

.badge-success[href]:hover,
.badge-success[href]:focus {
  background-color: #56822d;
  color: #212529;
}

.bg-success {
  background-color: #70a83b !important;
}

a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #56822d !important;
}

.border-success {
  border-color: #70a83b !important;
}

.btn-success {
  background-color: #70a83b;
  border-color: #70a83b;
  color: #212529;
}

.btn-success:hover {
  background-color: #5e8d31;
  border-color: #56822d;
  color: #212529;
}

.btn-success:focus,
.btn-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(112, 168, 59, 0.5);
}

.btn-success.disabled,
.btn-success:disabled {
  background-color: #70a83b;
  border-color: #70a83b;
  color: #212529;
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  background-color: #56822d;
  border-color: #4f7629;
  color: #212529;
}

.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(112, 168, 59, 0.5);
}

.btn-outline-success {
  background-color: transparent;
  border-color: #70a83b;
  color: #70a83b;
}

.btn-outline-success:hover {
  background-color: #70a83b;
  border-color: #70a83b;
  color: #212529;
}

.btn-outline-success:focus,
.btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(112, 168, 59, 0.5);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  background-color: transparent;
  color: #70a83b;
}

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  background-color: #70a83b;
  border-color: #70a83b;
  color: #212529;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(112, 168, 59, 0.5);
}

.list-group-item-success {
  background-color: #cce5b5;
  color: #334d1b;
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
  background-color: #bfdea2;
  color: #334d1b;
}

.list-group-item-success.list-group-item-action.active {
  background-color: #334d1b;
  border-color: #334d1b;
  color: #212529;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #cce5b5;
}

.table-hover .table-success:hover {
  background-color: #bfdea2;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #bfdea2;
}

.text-success {
  color: #70a83b !important;
}

a.text-success:hover,
a.text-success:focus {
  color: #56822d !important;
}

/*------------------------------------
- COLOR warning
------------------------------------*/
.alert-warning {
  background-color: #fefdfb;
  border-color: #fcf6e8;
  color: #a4720f;
}

.alert-warning hr {
  border-top-color: #faedd1;
}

.alert-warning .alert-link {
  color: #75520b;
}

.badge-warning {
  background-color: #edb441;
  color: #212529;
}

.badge-warning[href]:hover,
.badge-warning[href]:focus {
  background-color: #e5a015;
  color: #212529;
}

.bg-warning {
  background-color: #edb441 !important;
}

a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #e5a015 !important;
}

.border-warning {
  border-color: #edb441 !important;
}

.btn-warning {
  background-color: #edb441;
  border-color: #edb441;
  color: #212529;
}

.btn-warning:hover {
  background-color: #e9a720;
  border-color: #e5a015;
  color: #212529;
}

.btn-warning:focus,
.btn-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(237, 180, 65, 0.5);
}

.btn-warning.disabled,
.btn-warning:disabled {
  background-color: #edb441;
  border-color: #edb441;
  color: #212529;
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  background-color: #e5a015;
  border-color: #d79614;
  color: #212529;
}

.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(237, 180, 65, 0.5);
}

.btn-outline-warning {
  background-color: transparent;
  border-color: #edb441;
  color: #edb441;
}

.btn-outline-warning:hover {
  background-color: #edb441;
  border-color: #edb441;
  color: #212529;
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(237, 180, 65, 0.5);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  background-color: transparent;
  color: #edb441;
}

.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  background-color: #edb441;
  border-color: #edb441;
  color: #212529;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(237, 180, 65, 0.5);
}

.list-group-item-warning {
  background-color: #fcf6e8;
  color: #a4720f;
}

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
  background-color: #faedd1;
  color: #a4720f;
}

.list-group-item-warning.list-group-item-action.active {
  background-color: #a4720f;
  border-color: #a4720f;
  color: #212529;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fcf6e8;
}

.table-hover .table-warning:hover {
  background-color: #faedd1;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #faedd1;
}

.text-warning {
  color: #edb441 !important;
}

a.text-warning:hover,
a.text-warning:focus {
  color: #e5a015 !important;
}

/*------------------------------------
- COLOR danger
------------------------------------*/
.alert-danger {
  background-color: #f5d9db;
  border-color: #f1c9cb;
  color: #6c1c20;
}

.alert-danger hr {
  border-top-color: #ebb5b7;
}

.alert-danger .alert-link {
  color: #431114;
}

.badge-danger {
  background-color: #cb383f;
  color: #fff;
}

.badge-danger[href]:hover,
.badge-danger[href]:focus {
  background-color: #a42b30;
  color: #fff;
}

.bg-danger {
  background-color: #cb383f !important;
}

a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #a42b30 !important;
}

.border-danger {
  border-color: #cb383f !important;
}

.btn-danger {
  background-color: #cb383f;
  border-color: #cb383f;
  color: #fff;
}

.btn-danger:hover {
  background-color: #b12e34;
  border-color: #a42b30;
  color: #fff;
}

.btn-danger:focus,
.btn-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(203, 56, 63, 0.5);
}

.btn-danger.disabled,
.btn-danger:disabled {
  background-color: #cb383f;
  border-color: #cb383f;
  color: #fff;
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  background-color: #a42b30;
  border-color: #98272d;
  color: #fff;
}

.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(203, 56, 63, 0.5);
}

.btn-outline-danger {
  background-color: transparent;
  border-color: #cb383f;
  color: #cb383f;
}

.btn-outline-danger:hover {
  background-color: #cb383f;
  border-color: #cb383f;
  color: #fff;
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(203, 56, 63, 0.5);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  background-color: transparent;
  color: #cb383f;
}

.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  background-color: #cb383f;
  border-color: #cb383f;
  color: #fff;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(203, 56, 63, 0.5);
}

.list-group-item-danger {
  background-color: #f1c9cb;
  color: #6c1c20;
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
  background-color: #ebb5b7;
  color: #6c1c20;
}

.list-group-item-danger.list-group-item-action.active {
  background-color: #6c1c20;
  border-color: #6c1c20;
  color: #fff;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f1c9cb;
}

.table-hover .table-danger:hover {
  background-color: #ebb5b7;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #ebb5b7;
}

.text-danger {
  color: #cb383f !important;
}

a.text-danger:hover,
a.text-danger:focus {
  color: #a42b30 !important;
}

/*------------------------------------
- COLOR info
------------------------------------*/
.alert-info {
  background-color: #fcfdfe;
  border-color: #e9f0fc;
  color: #1043a4;
}

.alert-info hr {
  border-top-color: #d2e0fa;
}

.alert-info .alert-link {
  color: #0b3075;
}

.badge-info {
  background-color: #437dec;
  color: #fff;
}

.badge-info[href]:hover,
.badge-info[href]:focus {
  background-color: #175de4;
  color: #fff;
}

.bg-info {
  background-color: #437dec !important;
}

a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #175de4 !important;
}

.border-info {
  border-color: #437dec !important;
}

.btn-info {
  background-color: #437dec;
  border-color: #437dec;
  color: #fff;
}

.btn-info:hover {
  background-color: #2266e8;
  border-color: #175de4;
  color: #fff;
}

.btn-info:focus,
.btn-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(67, 125, 236, 0.5);
}

.btn-info.disabled,
.btn-info:disabled {
  background-color: #437dec;
  border-color: #437dec;
  color: #fff;
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  background-color: #175de4;
  border-color: #1558d6;
  color: #fff;
}

.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(67, 125, 236, 0.5);
}

.btn-outline-info {
  background-color: transparent;
  border-color: #437dec;
  color: #437dec;
}

.btn-outline-info:hover {
  background-color: #437dec;
  border-color: #437dec;
  color: #fff;
}

.btn-outline-info:focus,
.btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(67, 125, 236, 0.5);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  background-color: transparent;
  color: #437dec;
}

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  background-color: #437dec;
  border-color: #437dec;
  color: #fff;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(67, 125, 236, 0.5);
}

.list-group-item-info {
  background-color: #e9f0fc;
  color: #1043a4;
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
  background-color: #d2e0fa;
  color: #1043a4;
}

.list-group-item-info.list-group-item-action.active {
  background-color: #1043a4;
  border-color: #1043a4;
  color: #fff;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #e9f0fc;
}

.table-hover .table-info:hover {
  background-color: #d2e0fa;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #d2e0fa;
}

.text-info {
  color: #437dec !important;
}

a.text-info:hover,
a.text-info:focus {
  color: #175de4 !important;
}
